import React, { useState, useEffect, useMemo } from 'react'
import Image from 'next/image'
import { GetServerSideProps } from 'next'
import { GetServerSidePropsContext } from 'next'
import { QueryClient } from 'react-query'
import { dehydrate } from 'react-query/hydration'
import { useSetRecoilState } from 'recoil'
import SEOComponent from '../../components/SEOComponent'
import LiveTemplate from '../../components/Templates/Default/LiveTemplate'
import CustomTemplate from '../../components/Templates/Custom/CustomTemplate'
import validator from 'validator'
import {
    serverFetchPublicPrimaryPage,
    usePublicPrimaryPage,
} from '../../lib/hooks/usePublicPrimaryPage'
import { liveCampaignState } from '../../stores/campaign/live/state'
import { useAnalytics } from '../../lib/analytics/useAnalytics'
import { useRouter } from 'next/router'
import { serverFetchCustomerSession } from '../../lib/auth/fetchCustomerSession'
import nookies from 'nookies'
import { customerAuth } from '../../stores/auth/customer/customerAuth'
import Link from 'next/link'
import styled from 'styled-components'
import NotFound from '../404'
import Loader from '../../components/Common/Loader'

export const getServerSideProps: GetServerSideProps<{ username: string }> = async (
    context: GetServerSidePropsContext
) => {
    try {
        const query: any = context.params
        const username = query.username
        if (username === 'undefined') {
            throw new Error('invalid username')
        }
        const origin = process.env.NEXT_PUBLIC_BASE_URL

        const hostUrl = `${origin}/${username}`
        const queryClient = new QueryClient()

        await queryClient.prefetchQuery(
            ['publicCampaign', username],
            () => serverFetchPublicPrimaryPage(process.env.NEXT_PUBLIC_BASE_URL, username),
            { retry: 2 }
        )

        const dehydratedState = dehydrate(queryClient)

        const { data: campaign }: any = dehydratedState.queries[0].state

        if (campaign.message === 'username not found') {
            throw new Error('no username')
        }

        // check if redirect set
        if (
            campaign.redirect &&
            validator.isURL(campaign.redirectUrl, {
                protocols: ['https'],
                require_protocol: true,
                require_valid_protocol: true,
            })
        ) {
            context.res.writeHead(302, { Location: campaign.redirectUrl })
            context.res.end()
            return {
                props: {} as never,
            }
        }

        const cookies = nookies.get(context)
        const customer = await serverFetchCustomerSession(origin, cookies)

        return {
            props: {
                username,
                hostUrl,
                customer,
                dehydratedState,
            },
        }
    } catch (err) {
        console.log(err.message)
        return { props: {} as never }
    }
}

const LiveCampaign = ({ username, hostUrl, customer }) => {
    const router = useRouter()
    const { data: campaign, isFetching: campaignLoading } = usePublicPrimaryPage(username)
    const setCampaignState = useSetRecoilState(liveCampaignState)
    const setCustomerAuth = useSetRecoilState(customerAuth)
    let analytics = useAnalytics('live')

    useEffect(() => {
        async function updateView() {
            const res = await fetch('/api/campaign/update-views', {
                method: 'POST',
                body: JSON.stringify({
                    uid: campaign.uid,
                    campaignId: campaign.campaignId,
                }),
            })

            const message = await res.json()

            if (!res.ok) {
                console.log(message)
            }
        }

        if (campaign) {
            setCampaignState(campaign)
            updateView()
            if (Object.keys(analytics).length < 5) {
                const analytic = analytics.load(campaign.analytics)
                analytic.page(router.asPath)
            }

            setCustomerAuth(customer)
        }
    }, [campaign])

    const renderTemplate = useMemo(() => {
        if (campaign && campaign.views && campaign.views.length > 0) {
            return <CustomTemplate data={campaign} />
        } else if (!campaign || Object.keys(campaign).length === 0) {
            return <NotFound />
        } else {
            return <LiveTemplate data={campaign} />
        }
    }, [campaign])

    return (
        <>
            <SEOComponent
                hostUrl={hostUrl}
                image={campaign?.metaImageUrl || campaign?.logoImageUrl}
                siteTitle={campaign?.campaignName}
                description={campaign?.campaignDescription}
                font={campaign?.font}
                fonts={campaign?.fontFamilies || []}
            />

            {campaignLoading ? <Loader isLoading={campaignLoading} /> : renderTemplate}
        </>
    )
}

export default LiveCampaign
