import { useQuery } from 'react-query'
import validator from 'validator'
import Campaign from '../../../types/Campaign'
import CustomCampaign from '../../../types/Templates/Custom'

const serverFetchPublicPrimaryPage = async (
    url: string,
    username: string
): Promise<Campaign | CustomCampaign> => {
    if (username === undefined || validator.isEmpty(username)) {
        return undefined
    }

    const res = await fetch(`${url}/api/campaign/get-primary-public`, {
        method: 'POST',
        body: JSON.stringify({
            username: username,
        }),
    })

    if (!res.ok) {
        throw new Error(res.statusText)
    }

    const data = await res.json()

    return data
}

interface PromiseWithCancel<T> extends Promise<T> {
    cancel: () => void
}

const fetchPublicPrimaryPage = async (username) => {
    if (username === undefined || validator.isEmpty(username)) {
        return undefined
    }

    const controller = new AbortController()
    const signal = controller.signal

    const promise = new Promise(async (resolve, reject) => {
        try {
            const res = await fetch(`/api/campaign/get-primary-public`, {
                method: 'POST',
                body: JSON.stringify({
                    username: username,
                }),
                signal,
            })

            if (!res.ok) {
                console.log(res.statusText)
                throw new Error(res.statusText)
            }

            const data = await res.json()
            // TODO - assert data
            resolve(data)
        } catch (error: unknown) {
            if (isAbortError(error)) {
                reject(new Error('Request cancelled'))
            } else {
                reject(new Error('Something went wrong'))
            }
        }
    })
    ;(promise as PromiseWithCancel<any>).cancel = () => {
        controller.abort()
    }
    return promise as PromiseWithCancel<any>
}

function isAbortError(error: any): error is DOMException {
    if (error && error.name === 'AbortError') {
        return true
    }
    return false
}

const usePublicPrimaryPage = (username) => {
    return useQuery(['publicCampaign', username], () => fetchPublicPrimaryPage(username), {
        retry: 3,
        refetchOnWindowFocus: false,
    })
}

export { usePublicPrimaryPage, fetchPublicPrimaryPage, serverFetchPublicPrimaryPage }
