import React, { useEffect } from 'react'
import Image from 'next/image'
import styled from 'styled-components'
import Link from 'next/link'
import { useRouter } from 'next/router'

const NotFound = () => {
    const router = useRouter()

    useEffect(() => {
        setTimeout(() => {
            router.push('/')
        }, 2500)
    }, [router])

    return (
        <div className="flex flex-col w-screen h-screen items-center justify-center bg-gray-900">
            <Image
                src="/images/dashboard/Deployable_Logo_Orange_White.png"
                layout="fixed"
                objectFit="contain"
                width={150}
                height={30}
                alt="deployable-logo"
            />
            <h1 className="text-3xl my-8 text-white">404 Page does not exist</h1>
            <HomeLink>
                Go back to the <Link href={'/'}>Homepage</Link>
            </HomeLink>
        </div>
    )
}

export default NotFound

const HomeLink = styled.h2`
    color: #fff;
    a {
        color: #ff7d34;
        text-decoration: underline;
    }
`
